var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tools float-right"},[_c('base-dropdown',{attrs:{"menuClasses":"dropdown-menu-right"}},[_c('div',{staticClass:"nav-link dropdown-toggle text-primary",attrs:{"slot":"title","data-toggle":"dropdown"},slot:"title"},[_c('i',{staticClass:"ni ni-settings-gear-65"})]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"}},[_vm._v("Edit Profile")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"}},[_vm._v("Settings")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:;"}},[_vm._v("Log out")]),_c('a',{staticClass:"dropdown-item text-danger",attrs:{"href":"javascript:;"}},[_vm._v("Remove data")])])],1),_c('el-table',{staticClass:"table table-striped table-flush align-items-center mb-0",attrs:{"header-row-class-name":"text-primary","data":_vm.projects}},[_c('el-table-column',{attrs:{"label":"#","min-width":"20px","prop":"active","sortable":"","scope":"row"}},[[_c('div',{staticClass:"media align-items-center"},[_c('base-checkbox')],1)]],2),_c('el-table-column',{attrs:{"label":"Product name","min-width":"60px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Type","min-width":"60px","prop":"type","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_vm._v(" "+_vm._s(row.type)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Milestone","min-width":"60px","prop":"communication","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"progress-badge"},[_vm._v(" "+_vm._s(row.version)+" ")]),_c('base-progress',{attrs:{"type":"primary","value":row.completion}})],1)])]}}])}),_c('el-table-column',{attrs:{"label":"Qty","min-width":"60px","prop":"quantity","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_vm._v(" "+_vm._s(row.qty)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Price","min-width":"60px","prop":"price","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_vm._v(" "+_vm._s(row.price)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Amount","min-width":"60px","prop":"amount","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_vm._v(" "+_vm._s(row.amount)+" ")])]}}])})],1),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('td',{attrs:{"width":"300px"}}),_c('td',{attrs:{"width":"300px"}}),_c('td',{attrs:{"width":"300px"}}),_c('td',{staticClass:"td-total"},[_vm._v("Total")]),_c('td',{staticClass:"td-price"},[_vm._v("€ 35,999")])])}]

export { render, staticRenderFns }