var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table table-shopping"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.productsTable}},[_c('el-table-column',{attrs:{"min-width":"150","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":row.image,"alt":"Agenda"}})])}}])}),_c('el-table-column',{attrs:{"min-width":"220","label":"Product","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-name"},[_c('a',{attrs:{"href":"#jacket"}},[_vm._v(_vm._s(row.title))]),_c('br'),_c('small',[_vm._v(_vm._s(row.description))])])}}])}),_c('el-table-column',{attrs:{"min-width":"80","label":"Color","prop":"color","align":"left"}}),_c('el-table-column',{attrs:{"min-width":"60","label":"Size","prop":"size","align":"left"}}),_c('el-table-column',{attrs:{"min-width":"180","label":"Price","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_c('small',[_vm._v("€")]),_vm._v(" "+_vm._s(row.price)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"180","label":"Quantity","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.quantity)+" "),_c('div',{staticClass:"btn-group"},[_c('base-button',{attrs:{"type":"info","size":"sm"},nativeOn:{"click":function($event){return _vm.decreaseQuantity(row)}}},[_c('i',{staticClass:"ni ni-fat-delete"})]),_c('base-button',{attrs:{"type":"info","size":"sm"},nativeOn:{"click":function($event){return _vm.increaseQuantity(row)}}},[_c('i',{staticClass:"ni ni-fat-add"})])],1)])}}])}),_c('el-table-column',{attrs:{"min-width":"170","label":"Amount","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.amount)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"100","label":""}},[_c('div',{staticClass:"td-actions"},[_c('base-button',{staticClass:"text-danger",attrs:{"type":"","link":""}},[_c('i',{staticClass:"ni ni-fat-remove"})])],1)])],1),_c('div',{staticClass:"table table-stats"},[_c('div',{staticClass:"td-total"},[_vm._v(" Total ")]),_c('div',{staticClass:"td-price"},[_c('small',[_vm._v("€")]),_vm._v(" "+_vm._s(_vm.shoppingTotal)+" ")]),_vm._m(0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-info btn-round ",attrs:{"type":"button","rel":"tooltip","data-original-title":"","title":""}},[_vm._v(" Complete Purchase ")])])}]

export { render, staticRenderFns }