var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('small',{staticClass:"d-block text-uppercase font-weight-bold mb-3"},[_vm._v("Single date")]),_c('base-input',{attrs:{"addon-left-icon":"ni ni-calendar-grid-58"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.dates.simple),callback:function ($$v) {_vm.$set(_vm.dates, "simple", $$v)},expression:"dates.simple"}})}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('small',{staticClass:"d-block text-uppercase font-weight-bold mb-3"},[_vm._v("Date range")]),_c('div',{staticClass:"input-daterange datepicker row align-items-center"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"addon-left-icon":"ni ni-calendar-grid-58"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, mode: 'range' }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.dates.range),callback:function ($$v) {_vm.$set(_vm.dates, "range", $$v)},expression:"dates.range"}})}}])})],1)])]),_c('div',{staticClass:"col-md-4"},[_c('small',{staticClass:"d-block text-uppercase font-weight-bold mb-3"},[_vm._v("Datetimepicker")]),_c('div',{staticClass:"input-datetime datepicker row align-items-center"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"addon-left-icon":"ni ni-calendar-grid-58"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focus = ref.focus;
var blur = ref.blur;
return _c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{ allowInput: true, enableTime: true }},on:{"on-open":focus,"on-close":blur},model:{value:(_vm.dates.datetime),callback:function ($$v) {_vm.$set(_vm.dates, "datetime", $$v)},expression:"dates.datetime"}})}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }